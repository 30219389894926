<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="card">
      <b-container>
        <div class="col">
          <h3 style="text-align: center">Informações</h3>
          <hr />
          <form v-on:submit.prevent="updateClientInfo()" class="row">
            <div class="col-md-6">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Nome</span>
                </div>
                <input type="text" class="form-control form-control-sm" id="sku" v-model="first_name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Apelido</span>
                </div>
                <input type="text" class="form-control form-control-sm" id="sku" v-model="last_name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Notas</span>
                </div>
                <input type="text" class="form-control form-control-sm" id="sku" v-model="notes" />
              </div>
            </div>
            <hr />
            <!-- Emails -->
            <h5 style="text-align: center; color: white">Emails</h5>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Adicionar</span>
              </div>
              <input type="text" class="form-control form-control-sm" v-model="newEmail" @keydown.enter.prevent="addEmail" />
              <!-- <button class="btn btn-primary" @click.prevent="addEmail">Add</button> -->
            </div>
            <div v-for="(item, index) in email" :key="index" class="input-container">
              <div class="input-group">
                <input type="text" v-model="email[index]" placeholder="Enter text here" />
                <button class="delete-button" @click.prevent="removeEmail(index)">Apagar</button>
              </div>
            </div>
            <hr />
            <!-- Phones -->
            <h5 style="text-align: center; color: white">Telemóvel</h5>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Adicionar</span>
              </div>
              <input type="text" class="form-control form-control-sm" v-model="newPhone" @keydown.enter.prevent="addPhone" />
              <!-- <button class="btn btn-primary" @click.prevent="addEmail">Add</button> -->
            </div>
            <div v-for="(item, index) in phone" :key="index" class="input-container">
              <div class="input-group">
                <input type="text" v-model="phone[index]" />
                <button class="delete-button" @click.prevent="removePhone(index)">Apagar</button>
              </div>
            </div>
            <hr />
            <div>
              <b-form-checkbox v-model="emails_address" name="check-button" switch size="lg">Emails Moradas</b-form-checkbox>
              <b-form-checkbox v-model="emails_tracking" name="check-button" switch size="lg">Emails Tracking</b-form-checkbox>
              <b-form-checkbox v-model="scam" name="check-button" switch size="lg">Scam</b-form-checkbox>
            </div>
            <div id="btnUpdateAddress">
              <button type="submit" class="btn btn-primary">Guardar Alterações</button>
            </div>
          </form>
        </div>
      </b-container>
    </div>
    <div>
      <div class="card2">
        <h3 style="text-align: center; color: #000">Moradas</h3>
        <button class="btn btn-success btn-sm" v-b-modal.modal-2>Adicionar Morada</button>
        <b-table striped hover :items="addresses" :fields="fields_moradas" :per-page="perPage_moradas" :current-page="currentPage_moradas" thead-class="text-white" small>
          <template #cell(actions)="data">
            <p class="updateAddress" @click="passDataModal(data.item)">Atualizar</p>
            <p class="deleteAddress" @click="deleteAddress(data.item.id)">Apagar</p>
          </template>
        </b-table>
      </div>
    </div>
    <div>
      <div class="card2">
        <h3 style="text-align: center; color: #000">Encomendas</h3>
        <b-table striped hover :items="orders" :fields="filds_encomendas" :per-page="perPage_orders" :current-page="currentPage_orders" thead-class="text-white" small>
          <template #cell(date)="data">
            {{ splitDate(data.item.date) }}
          </template>
          <template #cell(articles)="data">
            <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
            <span v-for="article in JSON.parse(data.item.articles)" :key="article.id">
              <p>
                {{ article.quantity }} x {{ article.sku }} -
                <span>{{ article.name }} </span>
              </p>
            </span>
          </template>
          <template #cell(total)="data"> {{ data.item.total }} {{ data.item.coin }} </template>
        </b-table>
      </div>
    </div>
    <!-- Modais -->
    <b-modal ref="my-modal" id="modal-1" title="Editar Morada" hide-footer>
      <form v-on:submit.prevent="updateAddressOrder()">
        <div class="form-group">
          <label for="addressInput">Morada:</label>
          <input type="text" class="form-control" id="addressInput" v-model="addressUpdate" />
        </div>
        <div class="form-group">
          <label for="addressInput">Morada 2:</label>
          <input type="text" class="form-control" id="addressInput" v-model="address2Update" />
        </div>
        <div class="form-group">
          <label for="cityInput">Cidade:</label>
          <input type="text" class="form-control" id="cityInput" v-model="cityUpdate" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Código Postal:</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="postcodeUpdate" />
        </div>
        <div class="form-group">
          <label for="stateInput">Estado:</label>
          <input type="text" class="form-control" id="stateInput" v-model="stateUpdate" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select class="form-control" id="exampleFormControlSelect1" v-model="countryUpdate">
            <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.code }} ({{ country.name }})</option>
          </select>
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Guardar Alterações</button>
        </div>
      </form>
    </b-modal>
    <b-modal ref="my-modal-nova" id="modal-2" title="Adicionar Morada" hide-footer>
      <form v-on:submit.prevent="adicionarMorada()">
        <div class="form-group">
          <label for="addressInput">Morada:</label>
          <input type="text" class="form-control" id="addressInput" v-model="addressUpdate" />
        </div>
        <div class="form-group">
          <label for="addressInput">Morada 2:</label>
          <input type="text" class="form-control" id="addressInput" v-model="address2Update" />
        </div>
        <div class="form-group">
          <label for="cityInput">Cidade:</label>
          <input type="text" class="form-control" id="cityInput" v-model="cityUpdate" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Código Postal:</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="postcodeUpdate" />
        </div>
        <div class="form-group">
          <label for="stateInput">Estado:</label>
          <input type="text" class="form-control" id="stateInput" v-model="stateUpdate" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select class="form-control" id="exampleFormControlSelect1" v-model="countryUpdate">
            <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.code }} ({{ country.name }})</option>
          </select>
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Adicionar Morada</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      id: '',
      perPage_moradas: 20,
      currentPage_moradas: 1,
      perPage_orders: 20,
      currentPage_orders: 1,
      loading: false,
      client_info: [],
      fields_moradas: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'address',
          label: 'Morada',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'address2',
          label: 'Morada 2',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'city',
          label: 'Cidade',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'postcode',
          label: 'Código Postal',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'state',
          label: 'Estado',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'country',
          label: 'País',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'actions',
          label: 'Ações',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
      filds_encomendas: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'site',
          label: 'Site',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'total',
          label: 'Total',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'status',
          label: 'Estado',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
      countries: [],
      first_name: '',
      last_name: '',
      email: [],
      phone: [],
      notes: '',
      emails_address: false,
      emails_tracking: false,
      scam: false,
      addresses: [],
      orders: [],
      id_address_update: '',
      addressUpdate: '',
      address2Update: '',
      cityUpdate: '',
      postcodeUpdate: '',
      stateUpdate: '',
      countryUpdate: '',
      newEmail: '',
      newPhone: '',
    };
  },
  methods: {
    async getClientInfo() {
      try {
        this.id = this.$route.params.id;
        await this.$store.dispatch('getClientsInfo', {
          id: this.id,
        });
        this.client_info = this.getClientsInfo;
        this.first_name = this.client_info.client.first_name;
        this.last_name = this.client_info.client.last_name;
        this.email = this.client_info.client.email;
        this.phone = this.client_info.client.phone;
        this.notes = this.client_info.client.notes;
        this.emails_address = this.client_info.client.emails_address;
        this.emails_tracking = this.client_info.client.emails_tracking;
        this.scam = this.client_info.client.scam;
        this.addresses = this.client_info.addresses;
        this.orders = this.client_info.orders;
        this.perPage_moradas = this.addresses.length;
        this.perPage_orders = this.orders.length;
      } catch (err) {
        alert(err);
      }
    },
    async allCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
      } catch (err) {
        alert(err);
      }
    },

    //Client
    async updateClientInfo() {
      try {
        await this.$store.dispatch('updateClientsInfo', {
          id: this.id,
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          notes: this.notes,
          emails_address: this.emails_address,
          emails_tracking: this.emails_tracking,
          scam: this.scam,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Informações Atualizadas`,
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getClientInfo();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //Emails
    addEmail() {
      if (this.newEmail.trim() !== '') {
        this.email.push(this.newEmail);
        this.newEmail = ''; // Clear the input field
      }
    },
    removeEmail(index) {
      this.email.splice(index, 1);
    },
    //Phone
    addPhone() {
      if (this.newPhone.trim() !== '') {
        this.phone.push(this.newPhone);
        this.newPhone = ''; // Clear the input field
      }
    },
    removePhone(index) {
      this.phone.splice(index, 1);
    },

    //Addresses
    async adicionarMorada() {
      try {
        await this.$store.dispatch('addClientAddress', {
          id: this.id,
          address: this.addressUpdate,
          address2: this.address2Update,
          city: this.cityUpdate,
          postcode: this.postcodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Morada Adicionada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['my-modal-nova'].hide();
        this.addressUpdate = '';
        this.address2Update = '';
        this.cityUpdate = '';
        this.postcodeUpdate = '';
        this.stateUpdate = '';
        this.countryUpdate = '';
        await this.getClientInfo();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async passDataModal(data) {
      this.data = data;
      this.id_address_update = data.id;
      this.addressUpdate = data.address;
      this.address2Update = data.address2;
      this.cityUpdate = data.city;
      this.postcodeUpdate = data.postcode;
      this.stateUpdate = data.state;
      this.countryUpdate = data.country;
      this.$refs['my-modal'].show();
    },
    async updateAddressOrder() {
      try {
        await this.$store.dispatch('updateClientAddress', {
          id: this.id,
          address_id: this.id_address_update,
          address: this.addressUpdate,
          address2: this.address2Update,
          city: this.cityUpdate,
          postcode: this.postcodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Morada Editada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['my-modal'].hide();
        this.id_address_update = '';
        this.addressUpdate = '';
        this.address2Update = '';
        this.cityUpdate = '';
        this.postcodeUpdate = '';
        this.stateUpdate = '';
        this.countryUpdate = '';
        await this.getClientInfo();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async deleteAddress(address_id) {
      this.$swal({
        title: 'Quer apagar a morada ' + address_id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, apagar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('deleteClientAddress', {
              id: this.id,
              address_id: address_id,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Morada ${address_id} apagada`,
              showConfirmButton: false,
              timer: 1500,
            });
            await this.getClientInfo();
          } catch (err) {
            console.log(err);
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: err,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    },
    //Extra
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
  },
  computed: {
    ...mapGetters(['getClientsInfo']),
    ...mapGetters(['getAllCountries']),
  },
  async created() {
    await this.getClientInfo();
    await this.allCountries();
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card2 {
  padding: 25px;
  background-color: rgba(255, 188, 188, 0.733);
  margin: 50px;
  border: 1px solid #000000dd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form {
  width: 200px;
}
.form-group {
  margin: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  margin-top: 20px;
  float: right;
  background-color: rgb(104, 155, 75);
  border: none;
}
button:hover {
  background-color: rgb(74, 106, 192);
}

h3 {
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.col-md-6 {
  margin-bottom: 5px;
}
.input-group-text {
  background-color: rgb(74, 106, 192);
  color: white;
}

.updateAddress {
  color: rgb(74, 106, 192);
  cursor: pointer;
}
.updateAddress:hover {
  color: rgb(0, 0, 255);
  text-decoration: underline;
}

.deleteAddress {
  color: rgb(255, 0, 0);
  cursor: pointer;
}
.deleteAddress:hover {
  color: rgb(255, 0, 0);
  text-decoration: underline;
}

/* Teste */
/* Container styles */
.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Input styles */
.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

input[type='text'] {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button styles */
.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
